import React from 'react'
import Navbar from '../French/NavbarFr'
import SecondSection from '../../SecondSection'
import ProgramFr from '../../Pages/French/ProgramFr'
import AboutUsFr from '../../Pages/French/AboutUsFr'
import FooterFr from '../../Pages/French/FooterFr'


const Fr = () => {
    return (
        <div>
            <div>
                {/* <NavbarFr /> */}
                <SecondSection />
                <AboutUsFr />
                <ProgramFr />
                <FooterFr />
            </div>
        </div>
    )
}

export default Fr
