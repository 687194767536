import React from 'react'
import '../css/AboutUs.css'
import ReactAudioPlayer from 'react-audio-player';
import tazkia from '../assets/voice.mp3'


const AboutUs = () => {
    return (
        <>
            <div className='about-container'>
                <img src={require('../assets/3.jpg')} alt="" className='bg-about' />
                <div className="about-sub-container">
                    <h1>عن الدار</h1>
                    <h4 className='container mt-5'>

                        الحمد لله وكفى، وسلام على عباده الذين اصطفى، وآله وصحبه المستكملين الشرفا، وبعد: فإن دار الكتاب والسنة المصرية؛ لتعليم اللغة العربية للناطقين بغيرها، تفتح ذراعيها لجميع طلاب العلم الوافدين، وقد حرصنا غاية الحرص على انتقاء ثلة من الأساتذة والمدرسين المتخصصين في اللغة العربية والقرآن وعلومه، فنسأل الله -سبحانه وتعالى- أن يوفقنا إلى ما يحب ويرضى، وأن يجعل أعمالنا خالصة لوجهه الكريم، إنه ولي ذلك والقادر عليه.                    </h4>
                </div>
            </div>
            <div className="tazkia">
                <h2 className='tazkia-title'>
                    تزكية فضيلة الشيخ الوالد سالم بن عبد الله بامحرز - حفظه الله-.
                </h2>
                <div>
                    <img src={require('../assets/tazkia.jpeg')} alt="" />
                    <div>
                        <ReactAudioPlayer
                            src={tazkia}
                            autoPlay={true}
                            controls
                            className='voice'
                        />
                    </div>


                </div>

            </div>
            <div className="hadith text-center">
                {/* <h1>
                    قال رسولُ اللهِ صلَّى اللهُ عليه وسلَّمَ :
                </h1>
                <h1 className=''>
                    "  إذا مات ابنُ آدمَ انقطع عملُه إلا من ثلاثٍ : صدقةٍ جاريةٍ ، وعلمٍ ينتفعُ به ، وولدٍ صالحٍ يدعو له
                    "                </h1>
                <h4 className='mt-4'>
                    المصدر : صحيح مسلم
                </h4> */}
                <h3 className=''>
                    عن صفوان بن عسال المرادي؛ قال: أتيتُ النبيَّ وهو في المسجدِ مُتَّكيءٌ على بُرْدٍ له أحمرَ، فقلتُ له: يا رسولَ اللهِ؛ إني جئتُ أَطلبُ العلمَ، فقال:
                    <span id='hello-student'> مرحبًا بطالبِ العلمِ</span>
                    ، إنَّ طالبَ العلمِ تحفُّه الملائكةُ [وتُظلُّه] بأجنحَتِها، ثم يركبُ بعضُهم بعضًا؛ حتى يبلغوا السماءَ الدُّنيا من محبَّتِهم لما يَطلبُ

                </h3>
                <h4 className='mt-4'>
                    المصدر : صحيح الترغيب، برقم (٧١)، قال الألباني: حديث حسن.
                </h4>
            </div>

        </>

    )
}

export default AboutUs
