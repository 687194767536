import React from 'react'
import Navbar from '../../Navbar'
// import '../../css/Pages/DarProg.css'
import '../../../css/Pages/DarProg.css'
import { Accordion } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import NavbarFr from './NavbarFr'


const DarProgFr = () => {
    return (
        <div>

            <div className='prog container' style={{ direction: 'ltr', listStyle: 'none' }}>
                <h1 className='mt-5'>
                    * Énoncé des buts et des objectifs de chaque niveau *
                </h1>
                <div>
                    <Accordion defaultActiveKey="0" className='mt-5'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Niveau préparatoire : Première moitié
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        Apprentissage des lettres de l'alphabet avec leurs points d'articulation, leurs caractéristiques, leur nombre, leurs noms et la manière de les écrire de façon isolée, au début, au milieu et à la fin du mot.
                                    </li>
                                    <li>
                                        Apprentissage de l'écriture de mots sans leur signification.
                                    </li>
                                    <li>
                                        Apprentissage des principes de base de la lecture de mots et de phrases sans leur signification.

                                    </li>
                                    <li>
                                        Apprentissage de quelques symboles et voyelles tels que  Ach-Chaddâh , At-Tanwîn, etc.

                                    </li>
                                    <li>
                                        Apprentissage de la lecture des mots avec l'article défini tout en connaissant les lettres solaires et lunaires.

                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Niveau préparatoire : Seconde moitié
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        Mémorisation d'un ensemble de vocabulaire de base sans leur pluriel.

                                    </li>
                                    <li>
                                        Mémorisation d'un ensemble de verbes de base au présent.

                                    </li>
                                    <li>
                                        Apprentissage à la composition de phrases simples incluant ses deux piliers principaux.

                                    </li>
                                    <li>
                                        Mémorisation de quatre-vingt-dix phrases.

                                    </li>
                                    <li>
                                        Apprentissage de règles de grammaire de base.

                                    </li>
                                    <li>
                                        Apprentissage à la conjugaison du verbe"sain" au présent.

                                    </li>
                                    <li>
                                        Apprentissage des règles de Tajweed les plus importantes.

                                    </li>
                                    <li>
                                        Mémorisation de la partie dite (Al-Moufassal) du Coran : De la Sourate An-Nâs à la Sourate Al-Bayyinah.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                Le premier niveau
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        Mémorisation d'un ensemble de vocabulaire de la vie quotidienne, avec leur pluriel.
                                    </li>
                                    <li>
                                        Mémorisation d'un groupe de verbes de bas eau passé, au présent et à l'impératif.

                                    </li>
                                    <li>
                                        Étude de dix sujets de la vie quotidienne ; chaque sujet sera enseigné en deux séances.

                                    </li>
                                    <li>
                                        Apprentissage à dialoguer sur les sujets étudiés.

                                    </li>
                                    <li>
                                        Apprentissage de règles de grammaire de base.

                                    </li>
                                    <li>
                                        Apprentissage à la conjugaison du verbe trilitère simple et sain au passé, présent et à l'impératif.

                                    </li>
                                    <li>
                                        Apprentissage des règles relatives aux nombres et au comptage entre 3 et 10, 20 et 90.

                                    </li>
                                    <li>
                                        Apprentissage des signes de ponctuation.

                                    </li>
                                    <li>
                                        Étude de la dictée à partir des sujets étudiés.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                Le second niveau
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        Mémorisation d'un ensemble de vocabulaire de la vie quotidienne avec le pluriel.

                                    </li>
                                    <li>
                                        Mémorisation d'un groupe de verbes de base au passé, présent, à l'impératif et à l'infinitif.

                                    </li>
                                    <li>
                                        Étude de 10 sujets de la vie quotidienne et chaque sujet sera enseigné en deux séances.

                                    </li>
                                    <li>
                                        Apprendre à dialoguer autour des sujets étudiés.

                                    </li>
                                    <li>
                                        Apprentissage des règles de grammaire de base.

                                    </li>
                                    <li>
                                        Apprentissage à conjuguer les verbes trilittéraux dits (Al-Mahmôuz), (Al-Moudâ'af) et (Al-Mou'tal) au passé, présent et à l'impératif.

                                    </li>
                                    <li>
                                        Apprentissagedes chiffres et des comptes entre 11 et 19 et entre 21 et 29.

                                    </li>
                                    <li>
                                        Apprentissage de l'heure.

                                    </li>
                                    <li>
                                        Apprentissage de la dictée à partir des sujets étudiés.

                                    </li>
                                    <li>
                                        Apprendre à s'exprimer sur les sujets étudiés
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                Le troisième niveau
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4 pb-5 odd'>
                                    <li>
                                        Mémorisation d'un ensemble de vocabulaire général de la vie avec leur pluriel.

                                    </li>
                                    <li>
                                        Mémorisation d'un groupe de verbes de base au passé, au présent, à l'impératif et à l'infinitif.

                                    </li>
                                    <li>
                                        Étude de vingt leçons divisées en quatre sujets principaux de la vie quotidienne, et chaque sujet comporte cinq leçons et une révision.

                                    </li>
                                    <li>
                                        Apprendre à composer des dialogues sur les sujets étudiés.

                                    </li>
                                    <li>
                                        Apprentissage des règles de grammaire et d'analyse syntaxique principales.

                                    </li>
                                    <li>
                                        Apprendre à chercher dans le dictionnaire.

                                    </li>
                                    <li>
                                        Apprentissage des bases de la conjugaison.

                                    </li>
                                    <li>
                                        Apprendre à conjuguer un verbe trilittéra le affixé et quadrilatérale au passé, présent et à l'impératif.

                                    </li>
                                    <li>
                                        Apprentissage des nombres et à compter après cent.
                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className='pb-5'>
                        <h3 className='mt-5'>
                            *  Prix ​​de l'abonnement mensuel  *
                        </h3>
                        <h5 className='text-danger mt-5 bb price'>
                            * La durée d'un niveau est de 6 semaines *
                        </h5>

                        <h5 className='text-danger mt-4 bb price'>
                            * La valeur de l'abonnement mensuel pour un niveau de langue arabe et du Saint Coran est de 4000 livres égyptiennes ou son équivalent *                        </h5>
                        <h5 className='text-danger mt-4 bb price'>
                            * La valeur de l'abonnement mensuel pour la langue arabe est de 2500 livres égyptiennes pour les résidents d'Égypte ou de 5 euros de l'heure pour les résidents hors d'Égypte *                        </h5>
                        <h5 className='text-danger mt-4 bb price'>
                            * La valeur de l'abonnement mensuel pour un niveau du Saint Coran n'est que de 2000 livres égyptiennes ou son équivalent *                        </h5>
                        <h5 className='text-danger mt-4   price'>
                            * Le prix du livre pour un niveau est de 300 livres égyptiennes ou son équivalent *
                        </h5>
                    </div>


                </div>
            </div>

        </div>
    )
}


export default DarProgFr
