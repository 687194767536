import React from 'react'

import SecondSection from './SecondSection'
import Footer from './Footer'
import Navbar from './Navbar'
import AboutUs from './AboutUs'
import Program from './Program'


const Home = () => {
  return (
    <div>
      <Navbar />
      <SecondSection />
      <AboutUs />
      <Program />
      <Footer/>
    </div>
  )
}

export default Home