import React, { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Input } from 'reactstrap'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import '../../../css/Login.css'



const SignIn = () => {


    return (
        <div className='m-auto'>
            <div className='google-form-container' >
                {/* <img src={require('../../assets/3.jpg')} alt="" className='bg-login' /> */}
                <div className='login-logo'>
                    <Link to='/' >
                        <img src={require('../../../assets/logo2.png')} alt="" />
                    </Link>
                </div>
            </div>
            <div className='ifram m-auto'>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSefh4fAGq3r06fRZpMIUnwRul7NpW9m02cnuJyOT-wkkkRvQg/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">جارٍ التحميل…</iframe>
            </div>
        </div>
    )
}

export default SignIn
