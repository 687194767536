import React from "react";
import '../../../css/Program.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1369 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 1368, min: 993 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 992, min: 481 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 480, min: 0 },
        items: 1
    }
};

const Program = () => {
    return (
        <>
            <div className="prog-container">
                <img src={require('../../../assets/3.jpg')} alt="" className='bg-prog' />
                <div className="prog-sub">
                    <div className="right-side">
                        <h1>
                            Le programme scolaire
                        </h1>
                        <Link to='/programs' >
                            <button className='more'>
                                Pour en savoir plus sur le programme
                            </button>
                        </Link>
                    </div>
                    {/* <img src={require('../assets/curve.png')} alt="" /> */}
                    <div className="slider-container">
                        <Carousel responsive={responsive} autoPlaySpeed={2000} infinite={true} itemClass="carousel-item-padding-10-px"
                        >

                            <div className='cars-img' >
                                <img src={require("../../../assets/cover1.jpg")} alt="" />
                            </div>
                            <div className='cars-img' >
                                <img src={require("../../../assets/cover2.jpg")} alt="" />
                            </div>
                            <div className='cars-img' >
                                <img src={require("../../../assets/cover3.jpg")} alt="" />
                            </div>
                            {/* <div className='cars-img' >
                                <img src={require("../assets/cover1.jpg")} alt="" />
                            </div> */}

                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="hadith text-center  ">
                {/* <h1 >
                    قال رسولُ اللهِ صلَّى اللهُ عليه وسلَّمَ :
                </h1> */}
                <h3 className=''>
                    عن أبي الدرداء رضي الله عنه، أن النبي صلى الله عليه وسلم قال: من سلك طريقًا يطلبُ فيه علمًا، سلك اللهُ به طريقًا من طرقِ الجنةِ، وإنَّ الملائكةَ لتضعُ أجنحتَها رضًا لطالبِ العِلمِ، وإنَّ العالِمَ ليستغفرُ له من في السماواتِ ومن في الأرضِ، والحيتانُ في جوفِ الماءِ، وإنَّ فضلَ العالمِ على العابدِ كفضلِ القمرِ ليلةَ البدرِ على سائرِ الكواكبِ، وإنَّ العلماءَ ورثةُ الأنبياءِ، وإنَّ الأنبياءَ لم يُورِّثُوا دينارًا ولا درهمًا، ورَّثُوا العِلمَ فمن أخذَه أخذ بحظٍّ وافرٍ.                     "
                </h3>
                <h4 className='mt-4'>
                    المصدر : صحيح: أخرجه أبو داود (٣٦٤١) واللفظ له، والترمذي (٢٦٨٢)، وابن ماجه (٢٢٣)، وأحمد (٢١٧١٥)، وصححه الألباني في صحيح سنن أبي داود.
                </h4>
                <h3 className="mt-5">
                    Le Prophète a dit :

                    « Quiconque emprunte une voie à la recherche d'un savoir, Allah lui facilitera une voie vers le Paradis. Certes, les Anges recouvrent de leurs ailes celui qui recherche le savoir en signe de satisfaction pour ce qu'il fait. Ceux qui se trouvent dans les cieux et sur la Terre, jusqu'aux poissons dans l'eau, implorent le pardon en faveur du savant. Et le mérite du savant sur le dévot est comparable au mérite de la lune par rapport aux autres astres. Les savants sont les héritiers des Prophètes. Or, les Prophètes n'ont laissé en héritage ni dinar, ni dirham, ils n'ont légué que le savoir. Quiconque s'empare de cet héritage a certes recueilli une part de bien considérable ».
                </h3>
                <h4>
                Source : Sahih Abi Daoud
                </h4>
            </div>
        </>

    )
}

export default Program
