import './App.css';
import '../src/css/Responsive.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Live from './components/Live';
import DarProg from './components/Pages/DarProg';
import DarProgFr from './components/Pages/French/DarProgFr';
import RegisterFr from './components/Pages/French/RegisterFr';
import Register from './components/Pages/Register'
import Fr from './components/Pages/French/French';
// import NavFrench from './components/Pages/French/NavFrench';
import NavbarFr from './components/Pages/French/NavbarFr'


document.body.classList.add('background-blur');

function App() {
  return (
    <div className="App">

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/programs' element={<DarProg />} />
      
        <Route path='/Register' element={<Register />} />
       
        <Route path='/live' element={<Live />} />
        <Route path='/fr' element={<NavbarFr />}>
          <Route index element={<Fr />} />
          <Route path='programme' element={<DarProgFr />} />
          <Route path='register' element={<RegisterFr />} />


        </Route>


      </Routes>
    </div>
  );
}

export default App;
