import React, { useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useState } from 'react';
import '../css/Navbar.css'
import {
    MDBNavbar,
    MDBContainer,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';


const Navbar = () => {
    const navigate = useNavigate()
    const [openNavCentred, setOpenNavCentred] = useState(false);

    const password = () => {

        const ramz = "!#DJa43g@566+6*4"
        const pass = prompt("ادخل الرمز")
        if (pass == ramz) {
            // alert('welcome')
            navigate('/live')
        } else {
            alert("الرمز غير صحيح")
        }

    }

    return (
        <div className='navbar-container'>
            <img src={require('../assets/3.jpg')} alt="" className='bg' />
            <ul className='lang-list d-flex'>
                <li><img src={require('../assets/lang.png')} alt="" width={30} style={{ fill: 'green' }} /></li>
                <li>العربية</li>
                <li>English</li>
                <li><Link to='/fr'>Français</Link></li>
                <li>Русский</li>
            </ul>
            <div className='container nav-in-container'>

                <div className=''>
                    <MDBNavbar expand='lg' light bgColor='' className='nav-ul container'>
                        <MDBContainer fluid>
                            <MDBNavbarToggler
                                type='button'
                                data-target='#navbarCenteredExample'
                                aria-controls='navbarCenteredExample'
                                aria-expanded='false'
                                aria-label='Toggle navigation'
                                onClick={() => setOpenNavCentred(!openNavCentred)}
                            >
                                <MDBIcon icon='bars' fas />
                            </MDBNavbarToggler>
                            <MDBCollapse navbar open={openNavCentred} center id='navbarCenteredExample'>
                                <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0'>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink aria-current='page' href='#' className='NavLink'>
                                            <Link to='/' className='NavLink'>الرئيسية</Link>
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink className='NavLink'>
                                            <Link to='/programs' className='NavLink'>برنامج الدار</Link>
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink aria-disabled='true' className='NavLink'>
                                            {/* <Link to='/login' className='NavLink'>تسجيل الدخول</Link> */}
                                            <Link to='/register' className='NavLink'> التسجيل في البرنامج</Link>
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem onClick={password}>
                                        <MDBNavbarLink className='NavLink' >

                                            <Link className='NavLink'  >  إلى البث</Link>
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                </MDBNavbarNav>
                            </MDBCollapse>
                        </MDBContainer>
                    </MDBNavbar>


                </div>
            </div>
        </div>
    )
}

export default Navbar
