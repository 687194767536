import React, { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../Navbar'
import axios from 'axios'
import Swal from 'sweetalert2'
import { Input } from 'reactstrap'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import '../../css/Login.css'



const SignIn = () => {
    const [studentName, setStudentName] = useState("")
    const [userName, setUserName] = useState("")
    const [country, setCountry] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [age, setAge] = useState()
    const [phone, setPhone] = useState()



    const navigat = useNavigate()

    const submit = async (e) => {
        e.preventDefault()
        await axios.post("https://dar-alketab-wasunnah.com/register", {
            studentName, country, email, password, age, phone
        }).then(response => {
            if (response.status) {
                navigat('/')
            }
        }).catch(err => console.log(err))



        Swal.fire({
            position: "center",
            icon: "success",
            title: "تم التسجيل بنجاح",
            showConfirmButton: false,
            timer: 2500
        });
    }


    return (
        <div className='m-auto'>
            <Navbar />
            <div className='google-form-container' >
                {/* <img src={require('../../assets/3.jpg')} alt="" className='bg-login' /> */}
                <div className='login-logo'>
                    <Link to='/' >
                        <img src={require('../../assets/logo2.png')} alt="" />
                    </Link>
                </div>
            </div>
            <div className='ifram m-auto'>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSefh4fAGq3r06fRZpMIUnwRul7NpW9m02cnuJyOT-wkkkRvQg/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">جارٍ التحميل…</iframe>
            </div>
        </div>
    )
}

export default SignIn
