import React from 'react'
import '../../../css/AboutUs.css'

const AboutUs = () => {
    return (
        <>
            <div className='about-container'>
                <img src={require('../../../assets/3.jpg')} alt="" className='bg-about' />
                <div className="about-sub-container">
                    <h1>À propos du centre</h1>
                    <h4 className='container mt-5'>
                        Louange à Allah, et Il nous suffit, et que la paix soit sur Ses serviteurs qu'Il a choisi, et sur sa famille et ses Compagnons qui ont atteint le summum du prestige.
                        Ceci étant :
                        L'institut égyptien du Coran et de la Sunna ; pour l’enseignement de la langue arabe aux locuteurs non-arabophones ouvre ses portes à tous les étudiants étrangers ! Nous avons pris grand soin de sélectionner un groupe de professeurs et d'enseignants spécialisés dans la langue arabe, le Coran et ses sciences. Nous demandons à Allah le Tout-Puissant de nous guider vers ce qu'Il aime et qui Lui plaît, et de rendre nos œuvres pures pour Son visage  honorable. Il en est le Garant et l’immensément Capable.
                    </h4>
                </div>
            </div>
            <div className="hadith text-center">
                {/* <h1>
                    قال رسولُ اللهِ صلَّى اللهُ عليه وسلَّمَ :
                </h1>
                <h1 className=''>
                    "  إذا مات ابنُ آدمَ انقطع عملُه إلا من ثلاثٍ : صدقةٍ جاريةٍ ، وعلمٍ ينتفعُ به ، وولدٍ صالحٍ يدعو له
                    "                </h1>
                <h4 className='mt-4'>
                    المصدر : صحيح مسلم
                </h4> */}
                <h3 className=''>
                    عن صفوان بن عسال المرادي؛ قال: أتيتُ النبيَّ وهو في المسجدِ مُتَّكيءٌ على بُرْدٍ له أحمرَ، فقلتُ له: يا رسولَ اللهِ؛ إني جئتُ أَطلبُ العلمَ، فقال:
                    <span id='hello-student'> مرحبًا بطالبِ العلمِ</span>
                    ، إنَّ طالبَ العلمِ تحفُّه الملائكةُ [وتُظلُّه] بأجنحَتِها، ثم يركبُ بعضُهم بعضًا؛ حتى يبلغوا السماءَ الدُّنيا من محبَّتِهم لما يَطلبُ

                </h3>
                <h4 className='mt-4'>
                    المصدر : صحيح الترغيب، برقم (٧١)، قال الألباني: حديث حسن.
                </h4>
                <h3 className='mt-5'>D’après Safwân Ibn ’Assâl Al-Murâdîرضي الله عنه  qui rapporte : « Je me suis rendu auprès du Prophète à la mosquée alors qu’il était allongé sur un manteau rouge, et je lui ai dit : « Ô Messager d’Allah ! Je suis venu chercher la science. » Il répondit : « Bienvenue au chercheur de science ! Certes, les Anges entourent de leurs ailes celui qui cherche la science et lui font de l‘ombre, puis s’amoncellent les uns sur les autres jusqu’à atteindre le ciel le plus proche, par amour pour ce qu’il cherche. »</h3>
                <h4>Source : « Sahîh At-Targhîb »( n°71), Al-Albânî a dit : Hadith bon (Hassan)</h4>
            </div>

        </>

    )
}

export default AboutUs
