import React from 'react'
import { Link } from 'react-router-dom'

const Live = () => {
  return (
    <div className='text-center'>
      <div className='login-logo'>
        <Link to='/' >
          <img src={require('../assets/logo2.png')} alt="" />
        </Link>
      </div>

      <h2> صفحة البث </h2>
    </div>
  )
}

export default Live
