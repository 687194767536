import React from 'react'
import Navbar from '../Navbar'
import '../../css/Pages/DarProg.css'
import { Accordion } from 'react-bootstrap'


const DarProg = () => {
    return (
        <div>
            <Navbar />
            <div className='prog container'>
                <h1 className='mt-5'>
                    *  بيان مقاصد وأهداف المستويات *
                </h1>
                <div>
                    <Accordion defaultActiveKey="0" className='mt-5'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                -   المستوى التمهيدي النصف الأول
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        تعلم حرف الهجاء بمخارجها وصفاتها وعددها وأسمائها وكتابتها وحدها وفي أول الكلمة ووسطها وآخرها
                                    </li>
                                    <li>
                                        تعلم كتابة الكلمات من غير فهمها
                                    </li>
                                    <li>
                                        تعلم مبادئ قراءة الكلمات والجمل من غير فهمها

                                    </li>
                                    <li>
                                        تعلم بعض الرموز والأشكال كالشدة والتنوين ، وما أشبه .

                                    </li>
                                    <li>
                                        تعلم قراءة الكلمة بأداة التعريف مع معرفة الحروف الشمسية والقمرية

                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                - المستوى التمهيدي النصف الثاني
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        حفظ مجموعة من المفردات الأساسية بدون جمع.

                                    </li>
                                    <li>
                                        حفظ مجموعة من الأفعال المضارعة الأساسية.

                                    </li>
                                    <li>
                                        تعلم تكوين جملة بسيطة مستوفية لركنيها.

                                    </li>
                                    <li>
                                        حفظ تسعين جملة.

                                    </li>
                                    <li>
                                        تعلم قواعد أساسية.

                                    </li>
                                    <li>
                                        تعلم تصريف الفعل الصحيح السالم المضارع.

                                    </li>
                                    <li>
                                        تعلم أهم أحكام التجويد.

                                    </li>
                                    <li>
                                        حفظ قصار المفصل من سورة الناس إلى سورة البينة.

                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                -  المستوى الأول

                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        حفظ مجموعة من مفردات الحياة اليومية بالجمع.
                                    </li>
                                    <li>
                                        حفظ مجموعة من الافعال الاساسية بالماضي والمضارع والأمر.

                                    </li>
                                    <li>
                                        دراسة عشرة موضوعات من الحياة اليومية وكل موضوع يدرس في حصتين.

                                    </li>
                                    <li>
                                        تعلم المحاورة في الموضوعات المدروسة.

                                    </li>
                                    <li>
                                        تعلم قواعد أساسية.

                                    </li>
                                    <li>
                                        تعلم تصريف الفعل الثلاثي المجرد الصحيح السالم الماضي والمضارع والأمر.

                                    </li>
                                    <li>
                                        تعلم العدد والمعدود بين 3 و 10 و 20 و 90

                                    </li>
                                    <li>
                                        تعلم علامات الترقيم.

                                    </li>
                                    <li>
                                        تعلم الاملاء من الموضوعات المدروسة.

                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                - المستوى الثاني
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4'>
                                    <li>
                                        حفظ مجموعة من مفردات الحياة اليومية بالجمع.

                                    </li>
                                    <li>
                                        حفظ مجموعة من الافعال الاساسية بالماضي والمضارع والأمر والمصدر.

                                    </li>
                                    <li>
                                        دراسة 10 موضوعات من الحياة اليومية وكل موضوع يدرس في حصتين.

                                    </li>
                                    <li>
                                        تعلم المحاورة في الموضوعات المدروسة.

                                    </li>
                                    <li>
                                        تعلم قواعد أساسية.

                                    </li>
                                    <li>
                                        تعلم تصريف الفعل الثلاثي المجرد المهموز و المضاعف والمعتل الماضي والمضارع والأمر.

                                    </li>
                                    <li>
                                        تعلم العدد والمعدود بين 11 و 19 و بين 21 و29

                                    </li>
                                    <li>
                                        تعلم الساعة .

                                    </li>
                                    <li>
                                        تعلم الاملاء من الموضوعات المدروسة.

                                    </li>
                                    <li>
                                        تعلم الإنشاء في الموضوعات المدروسة

                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                -  المستوى الثالث

                            </Accordion.Header>
                            <Accordion.Body>
                                <ul className='ul-list mt-4 pb-5 odd'>
                                    <li>
                                        حفظ مجموعة من مفردات الحياة العامة بالجمع.

                                    </li>
                                    <li>
                                        حفظ مجموعة من الافعال الاساسية بالماضي والمضارع والأمر والمصدر.

                                    </li>
                                    <li>
                                        دراسة عشرين درسا مقسمة إلى أربعة موضوعات رئيسية من الحياة العامة وكل موضوع فيه خمسة دروس ومراجعة.

                                    </li>
                                    <li>
                                        تعلم الإنشاء المحاورة في الموضوعات المدروسة.

                                    </li>
                                    <li>
                                        تعلم اهم قواعد النحو والإعراب.

                                    </li>
                                    <li>
                                        تعلم البحث في المعجم.

                                    </li>
                                    <li>
                                        تعلم أساسيات في علم الصرف.

                                    </li>
                                    <li>
                                        تعلم تصريف الفعل الثلاثي المزيد والرباعي الماضي والمضارع والأمر.

                                    </li>
                                    <li>
                                        تعلم العدد والمعدود بعد مائة.

                                    </li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className='pb-5'>
                        <h3 className='mt-5'>
                            *  قيمة الاشتراك الشهري  *
                        </h3>
                        <h5 className='text-danger mt-5 bb price'>
                            * مدة المستوى الواحد 6 أسابيع *
                        </h5>

                        <h5 className='text-danger mt-4 bb price'>
                            * قيمة الاشتراك الشهري في المستوى الواحد للغة العربية و القرآن الكريم 4000 جنيه مصري أو ما يعادله *
                        </h5>
                        <h5 className='text-danger mt-4 bb price'>
                            * قيمة الاشتراك الشهري  للغة العربية 2500 جنيه مصري للمقيمين في مصر أو 5 يورو للساعة للمقيمين بخارج مصر  *
                        </h5>
                        <h5 className='text-danger mt-4 bb price'>
                            * قيمة الاشتراك الشهري في المستوى الواحد للقرآن الكريم فقط 2000 جنيه مصري أو ما يعادله *
                        </h5>
                        <h5 className='text-danger mt-4   price'>
                            *  سعر الكتاب للمستوى الواحد 300 جنيه مصري أو ما يعادله  *
                        </h5>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default DarProg
