import React from 'react'
import '../css/SecondSection.css'

const SecondSection = () => {
  return (
    <div className='secondS-main-container'>
            <img src={require('../assets/second-bg.jpg')} className='second-bg' alt="logo" />

        <div className='secondS-sub-container text-center '>
            <img src={require('../assets/logo2.png')} className='main-logo' alt="logo" />
            {/* <p>مرحبا بكم في موقع دار الكتاب والسنة لتعليم اللغة العربية</p>           */}
        </div>
      
    </div>
  )
}

export default SecondSection
