import React from 'react'
import '../css/Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer '>
            <img src={require('../assets/3.jpg')} alt="" className='bg-footer' />
            <div className='footer-in  m-auto '>
                <div className='logo-with-markz item1'>
                    {/* <img src={require('../assets/logo-white.png')} alt="" className='logo-footer' /> */}
                    <img src={require('../assets/logo-footer.png')} className='logo-footer mt-4 text-center' alt="logo" />

                    <div className='markz mt-3'>
                        <h6 >  مركز دار الكتاب والسنة لتعليم اللغة العربية والعلوم الشرعية.<br />  تحت إشراف / سمير بن سعيد القاهري</h6>
                    </div>
                </div>
                <div className='contact-us m-auto text-center item2'>
                    <div className='contact'>
                        <h4>تواصل معنا</h4>
                        <div className='number mt-3'>
                            <div>

                            </div>
                            {/* <div className='mt-2'>
                                <span>
                                    <img src={require('../assets/phone.png')} width={30} alt="" className='me-3' />

                                </span>
                                +02 010 225 167 64
                            </div> */}
                        </div>

                        <div className='fixed-icons '>
                            <a href="https://www.facebook.com/profile.php?id=61556596301464&mibextid=uzlsIk">
                                <img src={require('../assets/fb-bl.png')} alt="" />
                            </a>

                            <a href="https://twitter.com/Dar_ktb_sunnah">
                                <img src={require('../assets/twitter.png')} alt="" />
                            </a>
                            <a href="https://wa.me/message/FDUWRDRHSLCDA1">
                                <span>
                                    <img src={require('../assets/whatsapp.png')} alt="" width={30} className='me-3' />
                                </span>

                            </a>
                          
                        </div>
                        <div className='work-time'>
                            <h3 className='mt-4'>
                                مواعيد العمل
                            </h3>
                            <h5 className='mt-1 '>
                                من الأحد إلى الخميس
                            </h5>
                            <h5>
                                من الساعة السابعة صباحًا إلى الثالثة عصرًا بتوقيت مصر
                            </h5>
                        </div>

                    </div>

                </div>
                <div className='middle-footer item3'>
                    <div className='location m-auto text-center'>

                        {/* <Link><li>من نحن؟</li></Link> */}
                        <Link><h4 className='text-light text-center mt-5'> موقع الدار</h4></Link>
                        <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3453.5885102433167!2d31.373441999999997!3d30.048661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAyJzU1LjIiTiAzMcKwMjInMjQuNCJF!5e0!3m2!1sen!2seg!4v1709092942667!5m2!1sen!2seg" width={350} height={250} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        {/* <Link><li>التسجيل في البرنامج الدراسي</li></Link> */}

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer
